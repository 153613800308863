import React, { useState, useEffect } from 'react'
import LogbookService from '../services/logbook.service'
import MedsListService from '../services/medslist.service'
import DatePicker from 'react-datepicker'
import { Link, withRouter } from 'react-router-dom'

const EditLog = (props) => {
  const { id } = props.computedMatch.params
  const initialLogState = {
    id: null,
    title: '',
    date: '',
    migraineDay: false,
    medication: [
      {
        name: '',
        amount: '',
        units: '',
      },
    ],
    entry: '',
  }
  const defaultMed = {
    name: '',
    amount: '',
    units: '',
  }

  const [log, setLog] = useState(initialLogState)
  const [message, setMessage] = useState('')
  const [logDate, setLogDate] = useState(new Date())
  const [migraineDay, setMigraineDay] = useState('')
  const [medFlag, setMedFlag] = useState(false)
  const [med, setMed] = useState(defaultMed)
  const [medsList, setMedsList] = useState([defaultMed])
  const [medsListFlag, setMedsListFlag] = useState(false)
  const [getMedsListFlag, setGetMedsListFlag] = useState(false)
  // const isFirstRender = useRef(true);

  const getLog = (id) => {
    LogbookService.get(id)
      .then((res) => {
        let dbDate = new Date(res.data.date)
        setLog({ ...res.data, date: dbDate })
        setLogDate(dbDate)
        setMigraineDay(res.data.migraineDay)
        if (res.data.medication.length < 1) {
          setGetMedsListFlag(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    getLog(id)
    // isFirstRender.current = false
  }, [id])

  const getMedsList = () => {
    MedsListService.getAll()
      .then((res) => {
        const data = res.data
        let medNamesAndUnits = []
        data.map((med) => {
          return medNamesAndUnits.push({
            name: med.name,
            amount: '',
            units: med.units,
          })
        })
        setMedsList(medNamesAndUnits)
        setMedsListFlag(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (getMedsListFlag) {
      getMedsList()
    }
    setGetMedsListFlag(false)
  }, [getMedsListFlag])

  useEffect(() => {
    if (medsListFlag) {
      setLog({
        ...log,
        medication: medsList,
      })
      setMedsListFlag(false)
    }
  }, [medsListFlag, log, medsList])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setLog({ ...log, [name]: value })
  }

  const handleDateChange = (date) => {
    setLogDate(date)
    setLog({
      ...log,
      date: date,
    })
  }

  const handleMigraineDayChange = (toggle) => {
    const value = toggle === 'yes' ? true : false
    setMigraineDay(value)
    setLog({ ...log, migraineDay: value })
  }

  const handleMedInput = (event) => {
    const { name, value } = event.target
    setMed({ ...med, [name]: value })
    setMedFlag(true)
  }

  useEffect(() => {
    if (medFlag) {
      for (const item of log.medication) {
        if (item.name === med.name) {
          item.amount = med.amount
          med.amount = ''
        }
      }
      // setLog({
      //   ...log,
      //   medication: [...medsList]
      // })
      setMedFlag(false)
    }
  }, [med, log, medFlag])

  // useEffect(() => {
  //   if (!isFirstRender.current) {
  //     console.log('date in 2nd useEffect', logDate)
  //     console.log('log date in 2nd useEffect', log.date)
  //   }
  // }, [logDate, log])

  const updateLog = () => {
    LogbookService.update(log.id, log)
      .then((res) => {
        setMessage('The log was updated successfully.')
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const deleteLog = () => {
    LogbookService.remove(log.id)
      .then((res) => {
        props.history.push('/logbook')
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className="container-lg my-3 pt-3">
      {log ? (
        <div className="">
          <h4 className="py-1">Edit log</h4>
          <form>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                value={log.title}
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <DatePicker
                selected={logDate}
                className="form-control"
                wrapperClassName="form-control"
                width="100%"
                onChange={(date) => handleDateChange(date)}
                name="date"
                // value={log.date}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
            <div className="container border rounded py-1 mb-3 d-flex">
              {/* <div className="row "> */}
              <div className="col-md-6">
                <span htmlFor="migraineDay">Migraine Day? </span>
              </div>
              <div className="col-md-6">
                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="migraineDay"
                    id="toggleYes"
                    value={migraineDay}
                    checked={migraineDay === true}
                    onChange={() => handleMigraineDayChange('yes')}
                    required
                  />
                  <span className="form-check-label" htmlFor="toggleYes">
                    Yes
                  </span>
                </div>
                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="migraineDay"
                    id="toggleNo"
                    value={migraineDay}
                    checked={migraineDay === false}
                    onChange={() => handleMigraineDayChange('no')}
                    required
                  />
                  <span className="form-check-label" htmlFor="toggleYes">
                    No
                  </span>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="form-group mb-3 ">
              <label htmlFor="date">Medication</label>
              {log &&
                log.medication.map((med, index) => {
                  return (
                    <div className="form-row">
                      <div className="col-md-6 mb-2">
                        <input
                          className="form-control"
                          value={med.name}
                          name="name"
                          onClick={handleMedInput}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <input
                          className="form-control"
                          type="text"
                          value={med.amount}
                          index={med.name}
                          onChange={handleMedInput}
                          name="amount"
                          placeholder="amount"
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="form-group">
              <label htmlFor="entry">Entry</label>
              <textarea
                type="text"
                rows="10"
                className="form-control"
                id="entry"
                name="entry"
                value={log.entry}
                onChange={handleInputChange}
              />
            </div>
          </form>
          <div className="mt-3">
            <p>{message}</p>
          </div>
          <div className="mb-3">
            <button
              type="submit"
              className="btn btn-success mr-2"
              onClick={updateLog}
            >
              Update
            </button>
            <button className="btn btn-warning mx-2" onClick={deleteLog}>
              Delete
            </button>
            <Link to={'/logbook'} className="btn btn-info mx-2">
              Home
            </Link>
            <Link to={'/add'} className="btn btn-light mx-2">
              Add New Log
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <br />
          <p>Please click on a log...</p>
        </div>
      )}
    </div>
  )
}

export default withRouter(EditLog)
