let API_HOST

process.env.REACT_APP_ENVIRONMENT === 'production'
  ? (API_HOST = 'https://trax-api.jaykannaiyan.com')
  : (API_HOST = 'http://localhost:9050')

// console.info('api_host:', API_HOST)

export const config = Object.freeze({
  api: {
    baseUrl: `${API_HOST}`,
  },
})
