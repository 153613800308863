import axios from 'axios'
import authHeader from './auth-header'
import { API_ROUTES } from '../config/apiRoutes.config'
import { config } from '../config/env.config'

const getAll = () => {
  return axios.get(`${config.api.baseUrl}/${API_ROUTES.MEDSLIST}`, {
    headers: authHeader(),
  })
}

const create = (data) => {
  return axios.post(`${config.api.baseUrl}/${API_ROUTES.MEDSLIST}`, data, {
    headers: authHeader(),
  })
}

const update = (id, data) => {
  return axios.put(`${config.api.baseUrl}/${API_ROUTES.MEDSLIST}/${id}`, data, {
    headers: authHeader(),
  })
}

const remove = (id) => {
  return axios.delete(`${config.api.baseUrl}/${API_ROUTES.MEDSLIST}/${id}`, {
    headers: authHeader(),
  })
}

const removeAll = () => {
  return axios.delete(`${config.api.baseUrl}/${API_ROUTES.MEDSLIST}`, {
    headers: authHeader(),
  })
}

export default {
  getAll,
  create,
  update,
  remove,
  removeAll,
}
