import axios from 'axios'
import { API_ROUTES } from '../config/apiRoutes.config'
import { config } from '../config/env.config'

const API_URL = `${config.api.baseUrl}/${API_ROUTES.AUTH}/`

const register = (username, email, password) => {
  return axios.post(API_URL + 'signup', {
    username,
    email,
    password,
  })
}

const login = (username, password) => {
  return axios
    .post(API_URL + 'signin', {
      username,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data))
      }

      return response.data
    })
}

const logout = () => {
  localStorage.removeItem('user')
  localStorage.clear()
  document.cookie.split(';').forEach((c) => {
    document.cookie = c
      .replace(/^ +/, '')
      .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/')
  })
  // We iterate through the array of cookies to replace its content with an expiry date of today (new Date().toUTCString())
  // Having today’s time and date essentially voids the cookie and it is removed from the browser.
}

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem('user'))
}

export default {
  register,
  login,
  logout,
  getCurrentUser,
}
