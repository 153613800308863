import React, { useState, useEffect } from 'react'
import LogbookService from '../services/logbook.service'
import { Link } from 'react-router-dom'

const Logbook = () => {
  const [logbook, setLogbook] = useState([])
  const [currentLogList, setCurrentLogList] = useState([])
  const [pageNumber, setPageNumber] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  const [pageNumFlag, setPageNumFlag] = useState(false)
  const [currentLog, setCurrentLog] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [searchString, setSearchString] = useState('')
  const [showReset, setShowReset] = useState(false)
  const [loading, setLoading] = useState(false)

  const numberOfLogsToDisplay = 10

  const retrieveLogbook = () => {
    LogbookService.getAll()
      .then((res) => {
        let arr = res.data
        arr.sort((a, b) => new Date(b.date) - new Date(a.date))
        setLogbook(arr)
        setLoading(false)
        // console.log('array:', arr)

        if (arr.length <= numberOfLogsToDisplay) {
          setPageNumber(1)
          setTotalPages(1)
          setCurrentLogList(arr)
        } else {
          setPageNumber(1)
          setTotalPages(Number(Math.ceil(arr.length / numberOfLogsToDisplay)))
          setCurrentLogList(arr.slice(0, numberOfLogsToDisplay))
        }
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    retrieveLogbook()
    setLoading(true)
  }, [])

  const handlePagination = (direction) => {
    if (direction === 'prev' && pageNumber < totalPages) {
      setPageNumber(pageNumber + 1)
      setPageNumFlag(true)
      setCurrentIndex(-1)
      setCurrentLog(null)
    } else if (direction === 'next' && pageNumber > 1) {
      setPageNumber(pageNumber - 1)
      setPageNumFlag(true)
      setCurrentIndex(-1)
      setCurrentLog(null)
    } else if (direction === 'oldest') {
      setPageNumber(totalPages)
      setPageNumFlag(true)
      setCurrentIndex(-1)
      setCurrentLog(null)
    } else if (direction === 'newest') {
      setPageNumber(1)
      setPageNumFlag(true)
      setCurrentIndex(-1)
      setCurrentLog(null)
    }
  }

  useEffect(() => {
    if (pageNumFlag) {
      if (pageNumber === 1) {
        setCurrentLogList(logbook.slice(0, numberOfLogsToDisplay))
      } else if (pageNumber > 1) {
        setCurrentLogList(
          logbook.slice(
            numberOfLogsToDisplay * (pageNumber - 1),
            numberOfLogsToDisplay * pageNumber
          )
        )
      }
    }
    setPageNumFlag(false)
  }, [pageNumber, logbook, pageNumFlag, numberOfLogsToDisplay])

  const refreshList = () => {
    retrieveLogbook()
    setCurrentLog(null)
    setCurrentIndex(-1)
    setLoading(true)
  }

  const dateFormat = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  }

  const handleSelectedLog = (log, index) => {
    const dateString = new Date(log.date).toLocaleString('en-US', dateFormat)
    const modifiedLog = { ...log, date: dateString }

    setCurrentLog(modifiedLog)
    setCurrentIndex(index)
  }

  const handleSearchInput = (e) => {
    const searchString = e.target.value
    setSearchString(searchString)
  }

  const handleSearchKeyPress = (e) => {
    // Number 13 is the "Enter" key on the keyboard
    if (e.keyCode === 13) handleSearchSubmit()
  }

  const handleSearchSubmit = () => {
    setLoading(true)

    LogbookService.fullTextSearch(searchString)
      .then((res) => {
        let arr = res.data
        arr.sort((a, b) => new Date(b.date) - new Date(a.date))
        setLogbook(arr)
        setLoading(false)

        if (arr.length <= numberOfLogsToDisplay) {
          setPageNumber(1)
          setTotalPages(1)
          setCurrentLogList(arr)
        } else {
          setPageNumber(1)
          setTotalPages(Number(Math.ceil(arr.length / numberOfLogsToDisplay)))
          setCurrentLogList(arr.slice(0, numberOfLogsToDisplay))
        }
        setShowReset(true)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  const handleSearchReset = () => {
    setSearchString('')
    setShowReset(false)
    refreshList()
  }

  return (
    <div className="list row mt-3">
      <div arai-label="search bar" className="container-lg mt-3 pt-1">
        <div className="input-group mb-3">
          <input
            type="text"
            className="form-control dark-search-input"
            placeholder="Full Text Search"
            value={searchString}
            onChange={handleSearchInput}
            onKeyUp={handleSearchKeyPress}
          />
          <div className="input-group-append">
            {showReset && (
              <button
                className="btn btn-outline-secondary dark-search-button"
                type="button"
                onClick={handleSearchReset}
              >
                Reset
              </button>
            )}
            <button
              className="btn btn-outline-secondary"
              type="button"
              onClick={handleSearchSubmit}
            >
              Search
            </button>
          </div>
        </div>
      </div>

      <div className="col-md-6 container ">
        <h4 className="py-2">Log Book</h4>
        <nav aria-label="Pagination">
          <ul className="pagination d-flex text-center">
            <li
              className="page-item page-link flex-fill dark-pagination"
              role="button"
              onClick={() => handlePagination('oldest')}
            >
              <span aria-hidden="true">&laquo;</span>
            </li>

            <li
              className="page-item page-link flex-fill dark-pagination"
              role="button"
              onClick={() => handlePagination('prev')}
            >
              <span aria-hidden="true">&lt; older</span>
            </li>

            <li className="page-item page-link flex-fill dark-pagination">
              <span aria-hidden="true">
                {pageNumber} / {totalPages}
              </span>
            </li>

            <li
              className="page-item page-link flex-fill dark-pagination"
              role="button"
              onClick={() => handlePagination('next')}
            >
              <span aria-hidden="true">newer &gt;</span>
            </li>

            <li
              className="page-item page-link flex-fill dark-pagination"
              role="button"
              onClick={() => handlePagination('newest')}
            >
              <span aria-hidden="true">&raquo;</span>
            </li>
          </ul>
        </nav>
        <ul className="list-group mb-3 ">
          {loading && (
            <div className="text-center">
              <div
                className="spinner-border text-light"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {currentLogList &&
            currentLogList.map((log, index) => {
              const dateString = new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              )
              return (
                <li
                  className={
                    'list-group-item d-flex justify-content-between ' +
                    (index === currentIndex
                      ? 'dark-log-active'
                      : 'dark-log-item')
                  }
                  onClick={() => handleSelectedLog(log, index)}
                  key={index}
                >
                  <div>{log.title}</div>
                  <div>{dateString}</div>
                </li>
              )
            })}
        </ul>
      </div>

      <div aria-label="selected log" className="col-md-6 mt-3">
        {currentLog ? (
          <div className="container px-4 py-4 border rounded">
            <h5>Selected Log</h5>
            <hr />
            <div className="mt-2">
              <label>
                <strong>Title:</strong>
              </label>{' '}
              {currentLog.title}
            </div>
            <div className="mt-2">
              <label>
                <strong>Date:</strong>
              </label>{' '}
              {currentLog.date}
            </div>
            <div className="mt-2">
              <label>
                <strong>Migraine Day:</strong>
              </label>{' '}
              {currentLog.migraineDay ? 'Yes' : 'No'}
            </div>
            <div className="mt-2">
              <label>
                <strong>Medication:</strong>
              </label>{' '}
              {currentLog.medication.map((med) => {
                return med.amount ? (
                  <li>
                    {med.name}: {med.amount} {med.units}
                  </li>
                ) : (
                  <div></div>
                )
              })}
            </div>
            <div className="mt-2">
              <label>
                <strong>Entry:</strong>
              </label>{' '}
              <p>{currentLog.entry}</p>
            </div>

            <Link to={'/logbook/' + currentLog.id} className="btn btn-light">
              Edit
            </Link>
          </div>
        ) : (
          <div>
            <br />
            <p>Please click on a log...</p>
          </div>
        )}
      </div>
    </div>
  )
}

export default Logbook
