import React, { useState, useEffect } from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { paper-plane } from '@fortawesome/free-solid-svg-icons';
import LogbookService from '../services/logbook.service'
import MedsListService from '../services/medslist.service'
import {
  VictoryChart,
  VictoryAxis,
  VictoryTheme,
  VictoryScatter,
  VictoryLabel,
  VictoryLine,
  // VictoryBar,
  // VictoryVoronoiContainer,
} from 'victory'

const Dashboard = () => {
  const defaultMed = {
    name: 'suminat',
    daysStart: 0,
    daysEnd: 30,
  }

  const [logbook, setLogbook] = useState([])
  const [logbookFlagForMedUse, setLogbookFlagForMedUse] = useState(false)
  const [loading, setLoading] = useState(false)
  const [lastMigraineDate, setLastMigraineDate] = useState(null)
  const [daysSinceLastMigraine, setDaysSinceLastMigraine] = useState(null)
  const [migraineDaysLast30Days, setMigraineDaysLast30Days] = useState(null)
  const [migraineDaysLast60Days, setMigraineDaysLast60Days] = useState(null)
  const [migraineDaysLast90Days, setMigraineDaysLast90Days] = useState(null)
  const [medUseDays, setMedUseDays] = useState(null)
  const [medUseDaysAllMeds, setMedUseDaysAllMeds] = useState(null)
  const [medTriptanUseDays, setMedTriptanUseDays] = useState(null)
  const [medNsaidUseDays, setMedNsaidUseDays] = useState(null)
  const [medsList, setMedsList] = useState([])
  const [medsLoading, setMedsLoading] = useState(false)
  const [selectedMed, setSelectedMed] = useState(defaultMed)
  const [dateAxis, setDateAxis] = useState([])
  const [uniqueDates, setUniqueDates] = useState(null)
  const [uniqueDatesAllMeds, setUniqueDatesAllMeds] = useState(null)
  const [dataFlag, setDataFlag] = useState(false)
  // const [dataFlagAllMeds, setDataFlagAllMeds] = useState(false)
  const [graphData, setGraphData] = useState([{ date: '12/12/12', count: 1 }])
  const [graphDataAllMeds, setGraphDataAllMeds] = useState([
    { date: '12/12/12', count: 1 },
  ])

  const [migraineDaysJan2022, setMigraineDaysJan2022] = useState(null)
  const [migraineDaysFeb2022, setMigraineDaysFeb2022] = useState(null)
  const [migraineDaysMar2022, setMigraineDaysMar2022] = useState(null)
  const [migraineDaysApr2022, setMigraineDaysApr2022] = useState(null)
  const [migraineDaysMay2022, setMigraineDaysMay2022] = useState(null)
  const [migraineDaysJun2022, setMigraineDaysJun2022] = useState(null)
  const [migraineDaysJul2022, setMigraineDaysJul2022] = useState(null)
  const [migraineDaysAug2022, setMigraineDaysAug2022] = useState(null)
  const [migraineDaysSep2022, setMigraineDaysSep2022] = useState(null)
  const [migraineDaysOct2022, setMigraineDaysOct2022] = useState(null)
  const [migraineDaysNov2022, setMigraineDaysNov2022] = useState(null)
  const [migraineDaysDec2022, setMigraineDaysDec2022] = useState(null)
  const [migraineDaysJan2023, setMigraineDaysJan2023] = useState(null)
  const [migraineDaysFeb2023, setMigraineDaysFeb2023] = useState(null)
  const [migraineDaysMar2023, setMigraineDaysMar2023] = useState(null)
  const [migraineDaysApr2023, setMigraineDaysApr2023] = useState(null)
  const [migraineDaysMay2023, setMigraineDaysMay2023] = useState(null)
  const [migraineDaysJun2023, setMigraineDaysJun2023] = useState(null)
  const [migraineDaysJul2023, setMigraineDaysJul2023] = useState(null)
  const [migraineDaysAug2023, setMigraineDaysAug2023] = useState(null)
  const [migraineDaysSep2023, setMigraineDaysSep2023] = useState(null)
  const [migraineDaysOct2023, setMigraineDaysOct2023] = useState(null)
  const [migraineDaysNov2023, setMigraineDaysNov2023] = useState(null)
  const [migraineDaysDec2023, setMigraineDaysDec2023] = useState(null)
  const [migraineDaysJan2024, setMigraineDaysJan2024] = useState(null)
  const [migraineDaysFeb2024, setMigraineDaysFeb2024] = useState(null)
  const [migraineDaysMar2024, setMigraineDaysMar2024] = useState(null)
  const [migraineDaysApr2024, setMigraineDaysApr2024] = useState(null)
  const [migraineDaysMay2024, setMigraineDaysMay2024] = useState(null)
  const [migraineDaysJun2024, setMigraineDaysJun2024] = useState(null)
  const [migraineDaysJul2024, setMigraineDaysJul2024] = useState(null)
  const [migraineDaysAug2024, setMigraineDaysAug2024] = useState(null)
  const [migraineDaysSep2024, setMigraineDaysSep2024] = useState(null)
  const [graphDataMonths, setGraphDataMonths] = useState(null)

  const dateFormat = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
    // hour: '2-digit',
    // minute: '2-digit',
    hour12: false,
  }

  const constructDateAxis = (daysStart, daysEnd) => {
    const dateFormat = {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour12: false,
    }
    let dateAxisArray = []
    for (let i = 0; i <= daysEnd - daysStart; i++) {
      dateAxisArray.push(
        new Date(
          Date.now() - 1000 * 60 * 60 * 24 * daysStart - 1000 * 60 * 60 * 24 * i
        ).toLocaleString('en-US', dateFormat)
      )
    }
    setDateAxis(dateAxisArray)
  }

  useEffect(() => {
    retrieveLogbook()
    setLoading(true)
    getMedsList()
    setMedsLoading(true)
  }, [])

  useEffect(() => {
    if (logbookFlagForMedUse) {
      calculateLastMigraineDay()
      calculateLast306090MigraineDays()
      calculateMigraineDays()
      calculateMedUse(
        selectedMed.name,
        selectedMed.daysStart,
        selectedMed.daysEnd
      )
      calculateMedUseAllMeds(selectedMed.daysStart, selectedMed.daysEnd)
      calculateTriptanUse(selectedMed.daysStart, selectedMed.daysEnd)
      calculateNsaidUse(selectedMed.daysStart, selectedMed.daysEnd)
      constructDateAxis(selectedMed.daysStart, selectedMed.daysEnd)
      setDataFlag(true)
      // setDataFlagAllMeds(true)
    }
    // setLogbookFlagForMedUse(false);
    // if set to false, then subsequent calculations don't run
    // eslint-disable-next-line
  }, [logbookFlagForMedUse])

  useEffect(() => {
    if (dataFlag) {
      constructGraphDataQueriedMed(dateAxis, uniqueDates)
      constructGraphDataAllMeds(dateAxis, uniqueDatesAllMeds)
    }
    setDataFlag(false)
    // eslint-disable-next-line
  }, [dataFlag])

  // useEffect(() => {
  //   if (dataFlagAllMeds) {
  //     constructGraphDataAllMeds(dateAxis, uniqueDatesAllMeds)
  //   }
  //   setDataFlagAllMeds(false)
  //   // eslint-disable-next-line
  // }, [dataFlagAllMeds])

  const retrieveLogbook = () => {
    LogbookService.getAll()
      .then((res) => {
        let arr = res.data
        arr.sort((a, b) => new Date(b.date) - new Date(a.date))
        setLogbook(arr)
        setLoading(false)
        setLogbookFlagForMedUse(true)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  // retrieving MedsList from api
  const getMedsList = () => {
    MedsListService.getAll()
      .then((res) => {
        const data = res.data
        let medNames = []
        data.map((med) => {
          return medNames.push(med.name)
        })
        setMedsList(medNames)
        setMedsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setMedsLoading(false)
      })
  }

  const parseDate = (str) => {
    // new Date("dateString") is browser-dependent and discouraged, so we'll write
    // a simple parse function for U.S. date format (which does no error checking)
    let splitDate = str.split('/') // into month, day, year
    return new Date(splitDate[2], splitDate[0] - 1, splitDate[1])
  }

  const datediff = (olderDate, newerDate) => {
    // Take the difference between the dates and divide by milliseconds per day.
    // Round to nearest whole number to deal with DST.
    return Math.round((newerDate - olderDate) / (1000 * 60 * 60 * 24)) // converted from ms to days
  }

  const calculateLastMigraineDay = () => {
    const mostRecentMigraineDay = logbook.find((log) => log.migraineDay)

    let mostRecentMigraineDate, calculatedDaysSinceLastMigraine
    if (mostRecentMigraineDay) {
      mostRecentMigraineDate = new Date(
        mostRecentMigraineDay.date
      ).toLocaleString('en-US', dateFormat)

      calculatedDaysSinceLastMigraine = datediff(
        parseDate(mostRecentMigraineDate),
        parseDate(new Date().toLocaleString('en-US', dateFormat))
      )
    }
    setLastMigraineDate(mostRecentMigraineDate)
    setDaysSinceLastMigraine(calculatedDaysSinceLastMigraine)
  }

  const calculateLast306090MigraineDays = () => {
    let logsLast30 = []
    let logsLast60 = []
    let logsLast90 = []

    const todaysDateInMs = Date.now() - 1000 * 60 * 60 * 24
    const thirtyDaysAgoInMs = Date.now() - 1000 * 60 * 60 * 24 * 30
    const sixtyDaysAgoInMs = Date.now() - 1000 * 60 * 60 * 24 * 60
    const ninetyDaysAgoInMs = Date.now() - 1000 * 60 * 60 * 24 * 90

    if (logbook) {
      for (let i = 0; i < logbook.length; i++) {
        const dateInMs = Date.parse(logbook[i].date)
        if (dateInMs > thirtyDaysAgoInMs && dateInMs <= todaysDateInMs) {
          logsLast30.push(logbook[i])
        } else if (
          dateInMs > sixtyDaysAgoInMs &&
          dateInMs <= thirtyDaysAgoInMs
        ) {
          logsLast60.push(logbook[i])
        } else if (
          dateInMs > ninetyDaysAgoInMs &&
          dateInMs <= sixtyDaysAgoInMs
        ) {
          logsLast90.push(logbook[i])
        }
      }
    }

    // console.log('logsLast90', logsLast90)

    let migraineDayLogsLast30 = []
    let uniqueDatesLast30 = {}

    for (let log of logsLast30) {
      if (log.migraineDay === true) {
        migraineDayLogsLast30.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsLast30.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }

    // count how many unique migraines days in last 30 days
    for (let log of migraineDayLogsLast30) {
      if (uniqueDatesLast30[log.formattedDate]) {
        uniqueDatesLast30[log.formattedDate]++
      } else {
        uniqueDatesLast30[log.formattedDate] = 1
      }
    }

    let migraineDayLogsLast60 = []
    let uniqueDatesLast60 = {}

    for (let log of logsLast60) {
      if (log.migraineDay === true) {
        migraineDayLogsLast60.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsLast60.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }

    // count how many unique migraines days in last 60 days
    for (let log of migraineDayLogsLast60) {
      if (uniqueDatesLast60[log.formattedDate]) {
        uniqueDatesLast60[log.formattedDate]++
      } else {
        uniqueDatesLast60[log.formattedDate] = 1
      }
    }

    let migraineDayLogsLast90 = []
    let uniqueDatesLast90 = {}

    for (let log of logsLast90) {
      if (log.migraineDay === true) {
        migraineDayLogsLast90.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsLast90.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }

    // count how many unique migraines days in last 90 days
    for (let log of migraineDayLogsLast90) {
      if (uniqueDatesLast90[log.formattedDate]) {
        uniqueDatesLast90[log.formattedDate]++
      } else {
        uniqueDatesLast90[log.formattedDate] = 1
      }
    }

    // console.log('migraineDayLogsLast30', migraineDayLogsLast30)

    setMigraineDaysLast30Days(Object.keys(uniqueDatesLast30).length)
    setMigraineDaysLast60Days(Object.keys(uniqueDatesLast60).length)
    setMigraineDaysLast90Days(Object.keys(uniqueDatesLast90).length)
  }

  const calculateMigraineDays = () => {
    let logsJan2022 = []
    let logsFeb2022 = []
    let logsMar2022 = []
    let logsApr2022 = []
    let logsMay2022 = []
    let logsJun2022 = []
    let logsJul2022 = []
    let logsAug2022 = []
    let logsSep2022 = []
    let logsOct2022 = []
    let logsNov2022 = []
    let logsDec2022 = []
    let logsJan2023 = []
    let logsFeb2023 = []
    let logsMar2023 = []
    let logsApr2023 = []
    let logsMay2023 = []
    let logsJun2023 = []
    let logsJul2023 = []
    let logsAug2023 = []
    let logsSep2023 = []
    let logsOct2023 = []
    let logsNov2023 = []
    let logsDec2023 = []
    let logsJan2024 = []
    let logsFeb2024 = []
    let logsMar2024 = []
    let logsApr2024 = []
    let logsMay2024 = []
    let logsJun2024 = []
    let logsJul2024 = []
    let logsAug2024 = []
    let logsSep2024 = []

    // https://www.unixtimestamp.com/, use midnight ET
    const janfirst2022InMs = 1641013200000
    const febfirst2022InMs = 1643691600000
    const marfirst2022InMs = 1646110800000
    const aprfirst2022InMs = 1648785600000
    const mayfirst2022InMs = 1651377600000
    const junfirst2022InMs = 1654056000000
    const julfirst2022InMs = 1656648000000
    const augfirst2022InMs = 1659326400000
    const sepfirst2022InMs = 1662004800000
    const octfirst2022InMs = 1664596800000
    const novfirst2022InMs = 1667275200000
    const decfirst2022InMs = 1669870800000
    const janfirst2023InMs = 1672549200000
    const febfirst2023InMs = 1675227600000
    const marfirst2023InMs = 1677646800000
    const aprfirst2023InMs = 1680321600000
    const mayfirst2023InMs = 1682913600000
    const junfirst2023InMs = 1685592000000
    const julfirst2023InMs = 1688184000000
    const augfirst2023InMs = 1690862400000
    const sepfirst2023InMs = 1693540800000
    const octfirst2023InMs = 1696132800000
    const novfirst2023InMs = 1698811200000
    const decfirst2023InMs = 1701406800000
    const janfirst2024InMs = 1704085200000
    const febfirst2024InMs = 1706763600000
    const marfirst2024InMs = 1709269200000
    const aprfirst2024InMs = 1711944000000
    const mayfirst2024InMs = 1714536000000
    const junfirst2024InMs = 1717214400000
    const julfirst2024InMs = 1719806400000
    const augfirst2024InMs = 1722484800000
    const sepfirst2024InMs = 1725163200000
    const octfirst2024InMs = 1727755200000

    if (logbook) {
      for (let i = 0; i < logbook.length; i++) {
        const dateInMs = Date.parse(logbook[i].date)
        if (dateInMs >= janfirst2022InMs && dateInMs < febfirst2022InMs) {
          logsJan2022.push(logbook[i])
        } else if (
          dateInMs >= febfirst2022InMs &&
          dateInMs < marfirst2022InMs
        ) {
          logsFeb2022.push(logbook[i])
        } else if (
          dateInMs >= marfirst2022InMs &&
          dateInMs < aprfirst2022InMs
        ) {
          logsMar2022.push(logbook[i])
        } else if (
          dateInMs >= aprfirst2022InMs &&
          dateInMs < mayfirst2022InMs
        ) {
          logsApr2022.push(logbook[i])
        } else if (
          dateInMs >= mayfirst2022InMs &&
          dateInMs < junfirst2022InMs
        ) {
          logsMay2022.push(logbook[i])
        } else if (
          dateInMs >= junfirst2022InMs &&
          dateInMs < julfirst2022InMs
        ) {
          logsJun2022.push(logbook[i])
        } else if (
          dateInMs >= julfirst2022InMs &&
          dateInMs < augfirst2022InMs
        ) {
          logsJul2022.push(logbook[i])
        } else if (
          dateInMs >= augfirst2022InMs &&
          dateInMs < sepfirst2022InMs
        ) {
          logsAug2022.push(logbook[i])
        } else if (
          dateInMs >= sepfirst2022InMs &&
          dateInMs < octfirst2022InMs
        ) {
          logsSep2022.push(logbook[i])
        } else if (
          dateInMs >= octfirst2022InMs &&
          dateInMs < novfirst2022InMs
        ) {
          logsOct2022.push(logbook[i])
        } else if (
          dateInMs >= novfirst2022InMs &&
          dateInMs < decfirst2022InMs
        ) {
          logsNov2022.push(logbook[i])
        } else if (
          dateInMs >= decfirst2022InMs &&
          dateInMs < janfirst2023InMs
        ) {
          logsDec2022.push(logbook[i])
        } else if (
          dateInMs >= janfirst2023InMs &&
          dateInMs < febfirst2023InMs
        ) {
          logsJan2023.push(logbook[i])
        } else if (
          dateInMs >= febfirst2023InMs &&
          dateInMs < marfirst2023InMs
        ) {
          logsFeb2023.push(logbook[i])
        } else if (
          dateInMs >= marfirst2023InMs &&
          dateInMs < aprfirst2023InMs
        ) {
          logsMar2023.push(logbook[i])
        } else if (
          dateInMs >= aprfirst2023InMs &&
          dateInMs < mayfirst2023InMs
        ) {
          logsApr2023.push(logbook[i])
        } else if (
          dateInMs >= mayfirst2023InMs &&
          dateInMs < junfirst2023InMs
        ) {
          logsMay2023.push(logbook[i])
        } else if (
          dateInMs >= junfirst2023InMs &&
          dateInMs < julfirst2023InMs
        ) {
          logsJun2023.push(logbook[i])
        } else if (
          dateInMs >= julfirst2023InMs &&
          dateInMs < augfirst2023InMs
        ) {
          logsJul2023.push(logbook[i])
        } else if (
          dateInMs >= augfirst2023InMs &&
          dateInMs < sepfirst2023InMs
        ) {
          logsAug2023.push(logbook[i])
        } else if (
          dateInMs >= sepfirst2023InMs &&
          dateInMs < octfirst2023InMs
        ) {
          logsSep2023.push(logbook[i])
        } else if (
          dateInMs >= octfirst2023InMs &&
          dateInMs < novfirst2023InMs
        ) {
          logsOct2023.push(logbook[i])
        } else if (
          dateInMs >= novfirst2023InMs &&
          dateInMs < decfirst2023InMs
        ) {
          logsNov2023.push(logbook[i])
        } else if (
          dateInMs >= decfirst2023InMs &&
          dateInMs < janfirst2024InMs
        ) {
          logsDec2023.push(logbook[i])
        } else if (
          dateInMs >= janfirst2024InMs &&
          dateInMs < febfirst2024InMs
        ) {
          logsJan2024.push(logbook[i])
        } else if (
          dateInMs >= febfirst2024InMs &&
          dateInMs < marfirst2024InMs
        ) {
          logsFeb2024.push(logbook[i])
        } else if (
          dateInMs >= marfirst2024InMs &&
          dateInMs < aprfirst2024InMs
        ) {
          logsMar2024.push(logbook[i])
        } else if (
          dateInMs >= aprfirst2024InMs &&
          dateInMs < mayfirst2024InMs
        ) {
          logsApr2024.push(logbook[i])
        } else if (
          dateInMs >= mayfirst2024InMs &&
          dateInMs < junfirst2024InMs
        ) {
          logsMay2024.push(logbook[i])
        } else if (
          dateInMs >= junfirst2024InMs &&
          dateInMs < julfirst2024InMs
        ) {
          logsJun2024.push(logbook[i])
        } else if (
          dateInMs >= julfirst2024InMs &&
          dateInMs < augfirst2024InMs
        ) {
          logsJul2024.push(logbook[i])
        } else if (
          dateInMs >= augfirst2024InMs &&
          dateInMs < sepfirst2024InMs
        ) {
          logsAug2024.push(logbook[i])
        } else if (
          dateInMs >= sepfirst2024InMs &&
          dateInMs < octfirst2024InMs
        ) {
          logsSep2024.push(logbook[i])
        }
      }
    }

    // Jan 2022
    let migraineDayLogsJan2022 = []
    let uniqueDatesJan2022 = {}
    for (let log of logsJan2022) {
      if (log.migraineDay === true) {
        migraineDayLogsJan2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsJan2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJan2022) {
      if (uniqueDatesJan2022[log.formattedDate]) {
        uniqueDatesJan2022[log.formattedDate]++
      } else {
        uniqueDatesJan2022[log.formattedDate] = 1
      }
    }

    // Feb 2022
    let migraineDayLogsFeb2022 = []
    let uniqueDatesFeb2022 = {}
    for (let log of logsFeb2022) {
      if (log.migraineDay === true) {
        migraineDayLogsFeb2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsFeb2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsFeb2022) {
      if (uniqueDatesFeb2022[log.formattedDate]) {
        uniqueDatesFeb2022[log.formattedDate]++
      } else {
        uniqueDatesFeb2022[log.formattedDate] = 1
      }
    }

    // Mar 2022
    let migraineDayLogsMar2022 = []
    let uniqueDatesMar2022 = {}
    for (let log of logsMar2022) {
      if (log.migraineDay === true) {
        migraineDayLogsMar2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsMar2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsMar2022) {
      if (uniqueDatesMar2022[log.formattedDate]) {
        uniqueDatesMar2022[log.formattedDate]++
      } else {
        uniqueDatesMar2022[log.formattedDate] = 1
      }
    }

    // Apr 2022
    let migraineDayLogsApr2022 = []
    let uniqueDatesApr2022 = {}
    for (let log of logsApr2022) {
      if (log.migraineDay === true) {
        migraineDayLogsApr2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsApr2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsApr2022) {
      if (uniqueDatesApr2022[log.formattedDate]) {
        uniqueDatesApr2022[log.formattedDate]++
      } else {
        uniqueDatesApr2022[log.formattedDate] = 1
      }
    }

    // May 2022
    let migraineDayLogsMay2022 = []
    let uniqueDatesMay2022 = {}
    for (let log of logsMay2022) {
      if (log.migraineDay === true) {
        migraineDayLogsMay2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsMay2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsMay2022) {
      if (uniqueDatesMay2022[log.formattedDate]) {
        uniqueDatesMay2022[log.formattedDate]++
      } else {
        uniqueDatesMay2022[log.formattedDate] = 1
      }
    }

    // Jun 2022
    let migraineDayLogsJun2022 = []
    let uniqueDatesJun2022 = {}
    for (let log of logsJun2022) {
      if (log.migraineDay === true) {
        migraineDayLogsJun2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsJun2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJun2022) {
      if (uniqueDatesJun2022[log.formattedDate]) {
        uniqueDatesJun2022[log.formattedDate]++
      } else {
        uniqueDatesJun2022[log.formattedDate] = 1
      }
    }

    // Jul 2022
    let migraineDayLogsJul2022 = []
    let uniqueDatesJul2022 = {}
    for (let log of logsJul2022) {
      if (log.migraineDay === true) {
        migraineDayLogsJul2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsJul2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJul2022) {
      if (uniqueDatesJul2022[log.formattedDate]) {
        uniqueDatesJul2022[log.formattedDate]++
      } else {
        uniqueDatesJul2022[log.formattedDate] = 1
      }
    }

    // Aug 2022
    let migraineDayLogsAug2022 = []
    let uniqueDatesAug2022 = {}
    for (let log of logsAug2022) {
      if (log.migraineDay === true) {
        migraineDayLogsAug2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsAug2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsAug2022) {
      if (uniqueDatesAug2022[log.formattedDate]) {
        uniqueDatesAug2022[log.formattedDate]++
      } else {
        uniqueDatesAug2022[log.formattedDate] = 1
      }
    }

    // Sep 2022
    let migraineDayLogsSep2022 = []
    let uniqueDatesSep2022 = {}
    for (let log of logsSep2022) {
      if (log.migraineDay === true) {
        migraineDayLogsSep2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsSep2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsSep2022) {
      if (uniqueDatesSep2022[log.formattedDate]) {
        uniqueDatesSep2022[log.formattedDate]++
      } else {
        uniqueDatesSep2022[log.formattedDate] = 1
      }
    }

    // Oct 2022
    let migraineDayLogsOct2022 = []
    let uniqueDatesOct2022 = {}
    for (let log of logsOct2022) {
      if (log.migraineDay === true) {
        migraineDayLogsOct2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsOct2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsOct2022) {
      if (uniqueDatesOct2022[log.formattedDate]) {
        uniqueDatesOct2022[log.formattedDate]++
      } else {
        uniqueDatesOct2022[log.formattedDate] = 1
      }
    }

    // Nov 2022
    let migraineDayLogsNov2022 = []
    let uniqueDatesNov2022 = {}
    for (let log of logsNov2022) {
      if (log.migraineDay === true) {
        migraineDayLogsNov2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsNov2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsNov2022) {
      if (uniqueDatesNov2022[log.formattedDate]) {
        uniqueDatesNov2022[log.formattedDate]++
      } else {
        uniqueDatesNov2022[log.formattedDate] = 1
      }
    }

    // Dec 2022
    let migraineDayLogsDec2022 = []
    let uniqueDatesDec2022 = {}
    for (let log of logsDec2022) {
      if (log.migraineDay === true) {
        migraineDayLogsDec2022.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsDec2022.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsDec2022) {
      if (uniqueDatesDec2022[log.formattedDate]) {
        uniqueDatesDec2022[log.formattedDate]++
      } else {
        uniqueDatesDec2022[log.formattedDate] = 1
      }
    }

    // Jan 2023
    let migraineDayLogsJan2023 = []
    let uniqueDatesJan2023 = {}
    for (let log of logsJan2023) {
      if (log.migraineDay === true) {
        migraineDayLogsJan2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsJan2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJan2023) {
      if (uniqueDatesJan2023[log.formattedDate]) {
        uniqueDatesJan2023[log.formattedDate]++
      } else {
        uniqueDatesJan2023[log.formattedDate] = 1
      }
    }

    // Feb 2023
    let migraineDayLogsFeb2023 = []
    let uniqueDatesFeb2023 = {}
    for (let log of logsFeb2023) {
      if (log.migraineDay === true) {
        migraineDayLogsFeb2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsFeb2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsFeb2023) {
      if (uniqueDatesFeb2023[log.formattedDate]) {
        uniqueDatesFeb2023[log.formattedDate]++
      } else {
        uniqueDatesFeb2023[log.formattedDate] = 1
      }
    }

    // Mar 2023
    let migraineDayLogsMar2023 = []
    let uniqueDatesMar2023 = {}
    for (let log of logsMar2023) {
      if (log.migraineDay === true) {
        migraineDayLogsMar2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsMar2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsMar2023) {
      if (uniqueDatesMar2023[log.formattedDate]) {
        uniqueDatesMar2023[log.formattedDate]++
      } else {
        uniqueDatesMar2023[log.formattedDate] = 1
      }
    }

    // Apr 2023
    let migraineDayLogsApr2023 = []
    let uniqueDatesApr2023 = {}
    for (let log of logsApr2023) {
      if (log.migraineDay === true) {
        migraineDayLogsApr2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsApr2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsApr2023) {
      if (uniqueDatesApr2023[log.formattedDate]) {
        uniqueDatesApr2023[log.formattedDate]++
      } else {
        uniqueDatesApr2023[log.formattedDate] = 1
      }
    }

    // May 2023
    let migraineDayLogsMay2023 = []
    let uniqueDatesMay2023 = {}
    for (let log of logsMay2023) {
      if (log.migraineDay === true) {
        migraineDayLogsMay2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsMay2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsMay2023) {
      if (uniqueDatesMay2023[log.formattedDate]) {
        uniqueDatesMay2023[log.formattedDate]++
      } else {
        uniqueDatesMay2023[log.formattedDate] = 1
      }
    }

    // Jun 2023
    let migraineDayLogsJun2023 = []
    let uniqueDatesJun2023 = {}
    for (let log of logsJun2023) {
      if (log.migraineDay === true) {
        migraineDayLogsJun2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsJun2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJun2023) {
      if (uniqueDatesJun2023[log.formattedDate]) {
        uniqueDatesJun2023[log.formattedDate]++
      } else {
        uniqueDatesJun2023[log.formattedDate] = 1
      }
    }

    // Jul 2023
    let migraineDayLogsJul2023 = []
    let uniqueDatesJul2023 = {}
    for (let log of logsJul2023) {
      if (log.migraineDay === true) {
        migraineDayLogsJul2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsJul2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJul2023) {
      if (uniqueDatesJul2023[log.formattedDate]) {
        uniqueDatesJul2023[log.formattedDate]++
      } else {
        uniqueDatesJul2023[log.formattedDate] = 1
      }
    }

    // Aug 2023
    let migraineDayLogsAug2023 = []
    let uniqueDatesAug2023 = {}
    for (let log of logsAug2023) {
      if (log.migraineDay === true) {
        migraineDayLogsAug2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsAug2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsAug2023) {
      if (uniqueDatesAug2023[log.formattedDate]) {
        uniqueDatesAug2023[log.formattedDate]++
      } else {
        uniqueDatesAug2023[log.formattedDate] = 1
      }
    }

    // Sep 2023
    let migraineDayLogsSep2023 = []
    let uniqueDatesSep2023 = {}
    for (let log of logsSep2023) {
      if (log.migraineDay === true) {
        migraineDayLogsSep2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsSep2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsSep2023) {
      if (uniqueDatesSep2023[log.formattedDate]) {
        uniqueDatesSep2023[log.formattedDate]++
      } else {
        uniqueDatesSep2023[log.formattedDate] = 1
      }
    }

    // Oct 2023
    let migraineDayLogsOct2023 = []
    let uniqueDatesOct2023 = {}
    for (let log of logsOct2023) {
      if (log.migraineDay === true) {
        migraineDayLogsOct2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsOct2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsOct2023) {
      if (uniqueDatesOct2023[log.formattedDate]) {
        uniqueDatesOct2023[log.formattedDate]++
      } else {
        uniqueDatesOct2023[log.formattedDate] = 1
      }
    }

    // Nov 2023
    let migraineDayLogsNov2023 = []
    let uniqueDatesNov2023 = {}
    for (let log of logsNov2023) {
      if (log.migraineDay === true) {
        migraineDayLogsNov2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount)
          ) {
            migraineDayLogsNov2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsNov2023) {
      if (uniqueDatesNov2023[log.formattedDate]) {
        uniqueDatesNov2023[log.formattedDate]++
      } else {
        uniqueDatesNov2023[log.formattedDate] = 1
      }
    }

    // Dec 2023
    let migraineDayLogsDec2023 = []
    let uniqueDatesDec2023 = {}
    for (let log of logsDec2023) {
      if (log.migraineDay === true) {
        migraineDayLogsDec2023.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsDec2023.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsDec2023) {
      if (uniqueDatesDec2023[log.formattedDate]) {
        uniqueDatesDec2023[log.formattedDate]++
      } else {
        uniqueDatesDec2023[log.formattedDate] = 1
      }
    }

    // Jan 2024
    let migraineDayLogsJan2024 = []
    let uniqueDatesJan2024 = {}
    for (let log of logsJan2024) {
      if (log.migraineDay === true) {
        migraineDayLogsJan2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsJan2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJan2024) {
      if (uniqueDatesJan2024[log.formattedDate]) {
        uniqueDatesJan2024[log.formattedDate]++
      } else {
        uniqueDatesJan2024[log.formattedDate] = 1
      }
    }

    // Feb 2024
    let migraineDayLogsFeb2024 = []
    let uniqueDatesFeb2024 = {}
    for (let log of logsFeb2024) {
      if (log.migraineDay === true) {
        migraineDayLogsFeb2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsFeb2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsFeb2024) {
      if (uniqueDatesFeb2024[log.formattedDate]) {
        uniqueDatesFeb2024[log.formattedDate]++
      } else {
        uniqueDatesFeb2024[log.formattedDate] = 1
      }
    }

    // Mar 2024
    let migraineDayLogsMar2024 = []
    let uniqueDatesMar2024 = {}
    for (let log of logsMar2024) {
      if (log.migraineDay === true) {
        migraineDayLogsMar2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsMar2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsMar2024) {
      if (uniqueDatesMar2024[log.formattedDate]) {
        uniqueDatesMar2024[log.formattedDate]++
      } else {
        uniqueDatesMar2024[log.formattedDate] = 1
      }
    }

    // Apr 2024
    let migraineDayLogsApr2024 = []
    let uniqueDatesApr2024 = {}
    for (let log of logsApr2024) {
      if (log.migraineDay === true) {
        migraineDayLogsApr2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsApr2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsApr2024) {
      if (uniqueDatesApr2024[log.formattedDate]) {
        uniqueDatesApr2024[log.formattedDate]++
      } else {
        uniqueDatesApr2024[log.formattedDate] = 1
      }
    }

    // May 2024
    let migraineDayLogsMay2024 = []
    let uniqueDatesMay2024 = {}
    for (let log of logsMay2024) {
      if (log.migraineDay === true) {
        migraineDayLogsMay2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsMay2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsMay2024) {
      if (uniqueDatesMay2024[log.formattedDate]) {
        uniqueDatesMay2024[log.formattedDate]++
      } else {
        uniqueDatesMay2024[log.formattedDate] = 1
      }
    }

    // Jun 2024
    let migraineDayLogsJun2024 = []
    let uniqueDatesJun2024 = {}
    for (let log of logsJun2024) {
      if (log.migraineDay === true) {
        migraineDayLogsJun2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsJun2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJun2024) {
      if (uniqueDatesJun2024[log.formattedDate]) {
        uniqueDatesJun2024[log.formattedDate]++
      } else {
        uniqueDatesJun2024[log.formattedDate] = 1
      }
    }

    // Jul 2024
    let migraineDayLogsJul2024 = []
    let uniqueDatesJul2024 = {}
    for (let log of logsJul2024) {
      if (log.migraineDay === true) {
        migraineDayLogsJul2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsJul2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsJul2024) {
      if (uniqueDatesJul2024[log.formattedDate]) {
        uniqueDatesJul2024[log.formattedDate]++
      } else {
        uniqueDatesJul2024[log.formattedDate] = 1
      }
    }

    // Aug 2024
    let migraineDayLogsAug2024 = []
    let uniqueDatesAug2024 = {}
    for (let log of logsAug2024) {
      if (log.migraineDay === true) {
        migraineDayLogsAug2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsAug2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsAug2024) {
      if (uniqueDatesAug2024[log.formattedDate]) {
        uniqueDatesAug2024[log.formattedDate]++
      } else {
        uniqueDatesAug2024[log.formattedDate] = 1
      }
    }

    // Sep 2024
    let migraineDayLogsSep2024 = []
    let uniqueDatesSep2024 = {}
    for (let log of logsSep2024) {
      if (log.migraineDay === true) {
        migraineDayLogsSep2024.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            migraineDayLogsSep2024.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }
    for (let log of migraineDayLogsSep2024) {
      if (uniqueDatesSep2024[log.formattedDate]) {
        uniqueDatesSep2024[log.formattedDate]++
      } else {
        uniqueDatesSep2024[log.formattedDate] = 1
      }
    }
    //

    setMigraineDaysJan2022(Object.keys(uniqueDatesJan2022).length)
    setMigraineDaysFeb2022(Object.keys(uniqueDatesFeb2022).length)
    setMigraineDaysMar2022(Object.keys(uniqueDatesMar2022).length)
    setMigraineDaysApr2022(Object.keys(uniqueDatesApr2022).length)
    setMigraineDaysMay2022(Object.keys(uniqueDatesMay2022).length)
    setMigraineDaysJun2022(Object.keys(uniqueDatesJun2022).length)
    setMigraineDaysJul2022(Object.keys(uniqueDatesJul2022).length)
    setMigraineDaysAug2022(Object.keys(uniqueDatesAug2022).length)
    setMigraineDaysSep2022(Object.keys(uniqueDatesSep2022).length)
    setMigraineDaysOct2022(Object.keys(uniqueDatesOct2022).length)
    setMigraineDaysNov2022(Object.keys(uniqueDatesNov2022).length)
    setMigraineDaysDec2022(Object.keys(uniqueDatesDec2022).length)
    setMigraineDaysJan2023(Object.keys(uniqueDatesJan2023).length)
    setMigraineDaysFeb2023(Object.keys(uniqueDatesFeb2023).length)
    setMigraineDaysMar2023(Object.keys(uniqueDatesMar2023).length)
    setMigraineDaysApr2023(Object.keys(uniqueDatesApr2023).length)
    setMigraineDaysMay2023(Object.keys(uniqueDatesMay2023).length)
    setMigraineDaysJun2023(Object.keys(uniqueDatesJun2023).length)
    setMigraineDaysJul2023(Object.keys(uniqueDatesJul2023).length)
    setMigraineDaysAug2023(Object.keys(uniqueDatesAug2023).length)
    setMigraineDaysSep2023(Object.keys(uniqueDatesSep2023).length)
    setMigraineDaysOct2023(Object.keys(uniqueDatesOct2023).length)
    setMigraineDaysNov2023(Object.keys(uniqueDatesNov2023).length)
    setMigraineDaysDec2023(Object.keys(uniqueDatesDec2023).length)
    setMigraineDaysJan2024(Object.keys(uniqueDatesJan2024).length)
    setMigraineDaysFeb2024(Object.keys(uniqueDatesFeb2024).length)
    setMigraineDaysMar2024(Object.keys(uniqueDatesMar2024).length)
    setMigraineDaysApr2024(Object.keys(uniqueDatesApr2024).length)
    setMigraineDaysMay2024(Object.keys(uniqueDatesMay2024).length)
    setMigraineDaysJun2024(Object.keys(uniqueDatesJun2024).length)
    setMigraineDaysJul2024(Object.keys(uniqueDatesJul2024).length)
    setMigraineDaysAug2024(Object.keys(uniqueDatesAug2024).length)
    setMigraineDaysSep2024(Object.keys(uniqueDatesSep2024).length)

    const graphDataMonths = [
      // {
      //   month: 'Jan 2022',
      //   count: Object.keys(uniqueDatesJan2022).length,
      // },
      // {
      //   month: 'Feb 2022',
      //   count: Object.keys(uniqueDatesFeb2022).length,
      // },
      // {
      //   month: 'Mar 2022',
      //   count: Object.keys(uniqueDatesMar2022).length,
      // },
      // {
      //   month: 'Apr 2022',
      //   count: Object.keys(uniqueDatesApr2022).length,
      // },
      // {
      //   month: 'May 2022',
      //   count: Object.keys(uniqueDatesMay2022).length,
      // },
      // {
      //   month: 'Jun 2022',
      //   count: Object.keys(uniqueDatesJun2022).length,
      // },
      // {
      //   month: 'Jul 2022',
      //   count: Object.keys(uniqueDatesJul2022).length,
      // },
      // {
      //   month: 'Aug 2022',
      //   count: Object.keys(uniqueDatesAug2022).length,
      // },
      // {
      //   month: 'Sep 2022',
      //   count: Object.keys(uniqueDatesSep2022).length,
      // },
      // {
      //   month: 'Oct 2022',
      //   count: Object.keys(uniqueDatesOct2022).length,
      // },
      // {
      //   month: 'Nov 2022',
      //   count: Object.keys(uniqueDatesNov2022).length,
      // },
      // {
      //   month: 'Dec 2022',
      //   count: Object.keys(uniqueDatesDec2022).length,
      // },
      // {
      //   month: 'Jan 2023',
      //   count: Object.keys(uniqueDatesJan2023).length,
      // },
      // {
      //   month: 'Feb 2023',
      //   count: Object.keys(uniqueDatesFeb2023).length,
      // },
      // {
      //   month: 'Mar 2023',
      //   count: Object.keys(uniqueDatesMar2023).length,
      // },
      {
        month: 'Apr 2023',
        count: Object.keys(uniqueDatesApr2023).length,
      },
      {
        month: 'May 2023',
        count: Object.keys(uniqueDatesMay2023).length,
      },
      {
        month: 'Jun 2023',
        count: Object.keys(uniqueDatesJun2023).length,
      },
      {
        month: 'Jul 2023',
        count: Object.keys(uniqueDatesJul2023).length,
      },
      {
        month: 'Aug 2023',
        count: Object.keys(uniqueDatesAug2023).length,
      },
      {
        month: 'Sep 2023',
        count: Object.keys(uniqueDatesSep2023).length,
      },
      {
        month: 'Oct 2023',
        count: Object.keys(uniqueDatesOct2023).length,
      },
      {
        month: 'Nov 2023',
        count: Object.keys(uniqueDatesNov2023).length,
      },
      {
        month: 'Dec 2023',
        count: Object.keys(uniqueDatesDec2023).length,
      },
      {
        month: 'Jan 2024',
        count: Object.keys(uniqueDatesJan2024).length,
      },
      {
        month: 'Feb 2024',
        count: Object.keys(uniqueDatesFeb2024).length,
      },
      {
        month: 'Mar 2024',
        count: Object.keys(uniqueDatesMar2024).length,
      },
      {
        month: 'Apr 2024',
        count: Object.keys(uniqueDatesApr2024).length,
      },
      {
        month: 'May 2024',
        count: Object.keys(uniqueDatesMay2024).length,
      },
      {
        month: 'Jun 2024',
        count: Object.keys(uniqueDatesJun2024).length,
      },
      {
        month: 'Jul 2024',
        count: Object.keys(uniqueDatesJul2024).length,
      },
      {
        month: 'Aug 2024',
        count: Object.keys(uniqueDatesAug2024).length,
      },
      {
        month: 'Sep 2024',
        count: Object.keys(uniqueDatesSep2024).length,
      },
    ]

    setGraphDataMonths(graphDataMonths)
  }

  const calculateMedUse = (queriedMed, daysStart, daysEnd) => {
    // splicing logbook to only look at the queried days
    let splicedLogs = []
    const queriedDaysStartInMs = Date.now() - 1000 * 60 * 60 * 24 * daysStart
    const queriedDaysEndInMs = Date.now() - 1000 * 60 * 60 * 24 * daysEnd
    const daysBuffer = 10
    const arrayLength =
      daysEnd - daysStart + daysBuffer < logbook.length
        ? daysEnd - daysStart + daysBuffer
        : logbook.length

    if (logbook) {
      for (let i = 0; i < arrayLength; i++) {
        const dateInMs = Date.parse(logbook[i].date)
        if (dateInMs > queriedDaysEndInMs && dateInMs <= queriedDaysStartInMs) {
          splicedLogs.push(logbook[i])
        }
      }
    }

    // if queried med found in spliced logs, push to new array
    let splicedLogsWithMedUse = []
    let uniqueDates = {}
    // console.log("splicedLogs", splicedLogs)

    for (let log of splicedLogs) {
      for (let med of log.medication) {
        if (med.name === queriedMed && med.amount) {
          splicedLogsWithMedUse.push({
            ...log,
            formattedDate: new Date(log.date).toLocaleString(
              'en-US',
              dateFormat
            ),
          })
        }
      }
    }

    // count how many unique days of queried med use
    for (let log of splicedLogsWithMedUse) {
      if (uniqueDates[log.formattedDate]) {
        uniqueDates[log.formattedDate]++
      } else {
        uniqueDates[log.formattedDate] = 1
      }
    }
    // console.log("uniqueDates:", uniqueDates)

    setUniqueDates(uniqueDates)

    const numberOfMedUseDays = Object.keys(uniqueDates).length
    setMedUseDays(numberOfMedUseDays)
    setDataFlag(true)
  }

  const calculateMedUseAllMeds = (daysStart, daysEnd) => {
    // splicing logbook to only look at the queried days
    let splicedLogs = []
    const queriedDaysStartInMs = Date.now() - 1000 * 60 * 60 * 24 * daysStart
    const queriedDaysEndInMs = Date.now() - 1000 * 60 * 60 * 24 * daysEnd
    const daysBuffer = 10
    const arrayLength =
      daysEnd - daysStart + daysBuffer < logbook.length
        ? daysEnd - daysStart + daysBuffer
        : logbook.length

    if (logbook) {
      for (let i = 0; i < arrayLength; i++) {
        const dateInMs = Date.parse(logbook[i].date)
        if (dateInMs > queriedDaysEndInMs && dateInMs <= queriedDaysStartInMs) {
          splicedLogs.push(logbook[i])
        }
      }
    }

    let splicedLogsWithMedUse = []
    let uniqueDatesAllMeds = {}

    for (let log of splicedLogs) {
      if (log.migraineDay === true) {
        splicedLogsWithMedUse.push({
          ...log,
          formattedDate: new Date(log.date).toLocaleString('en-US', dateFormat),
        })
      } else {
        for (let med of log.medication) {
          if (
            (med.name === 'suminat' && med.amount) ||
            (med.name === 'zolmitriptan' && med.amount) ||
            (med.name === 'rizatriptan' && med.amount) ||
            (med.name === 'naproxen' && med.amount) ||
            (med.name === 'toradol' && med.amount) ||
            (med.name === 'diclofenac sodium' && med.amount) ||
            (med.name === 'benadryl' && med.amount) ||
            (med.name === 'clonazepam' && med.amount) ||
            (med.name === 'ascomp-codeine' && med.amount) ||
            (med.name === 'vasograin' && med.amount)
          ) {
            splicedLogsWithMedUse.push({
              ...log,
              formattedDate: new Date(log.date).toLocaleString(
                'en-US',
                dateFormat
              ),
            })
          }
        }
      }
    }

    // count how many unique days of all med use
    for (let log of splicedLogsWithMedUse) {
      if (uniqueDatesAllMeds[log.formattedDate]) {
        uniqueDatesAllMeds[log.formattedDate]++
      } else {
        uniqueDatesAllMeds[log.formattedDate] = 1
      }
    }

    setUniqueDatesAllMeds(uniqueDatesAllMeds)

    const numberOfMedUseDays = Object.keys(uniqueDatesAllMeds).length
    setMedUseDaysAllMeds(numberOfMedUseDays)
    // setDataFlagAllMeds(true)
  }

  const constructGraphDataQueriedMed = (dateAxis, uniqueDates) => {
    const dateFormat = {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour12: false,
    }
    let uniqueDatesArray = []

    for (let date in uniqueDates) {
      uniqueDatesArray.push(new Date(date).toLocaleString('en-US', dateFormat))
    }

    let graphData = []
    for (let i = 0; i < dateAxis.length; i++) {
      let count = null
      for (let date of uniqueDatesArray) {
        if (date === dateAxis[i]) {
          count = 1
        }
      }
      graphData.push({
        date: dateAxis[i],
        count: count,
      })
    }
    // console.log(graphData)

    setGraphData(graphData)
  }

  const constructGraphDataAllMeds = (dateAxis, uniqueDatesAllMeds) => {
    const dateFormat = {
      month: '2-digit',
      day: '2-digit',
      year: '2-digit',
      hour12: false,
    }
    let uniqueDatesArray = []

    for (let date in uniqueDatesAllMeds) {
      uniqueDatesArray.push(new Date(date).toLocaleString('en-US', dateFormat))
    }

    let graphDataAllMeds = []
    for (let i = 0; i < dateAxis.length; i++) {
      let count = null
      for (let date of uniqueDatesArray) {
        if (date === dateAxis[i]) {
          count = 1
        }
      }
      graphDataAllMeds.push({
        date: dateAxis[i],
        count: count,
      })
    }

    setGraphDataAllMeds(graphDataAllMeds)
  }

  const handleMedInput = () => {
    const selectBox = document.getElementById('selectBox')
    const selectedMedName = selectBox.options[selectBox.selectedIndex].value
    const selectedDaysStart = document.getElementById('medDaysStart').value
    const selectedDaysEnd = document.getElementById('medDaysEnd').value
    setSelectedMed({
      ...selectedMed,
      daysStart: selectedDaysStart,
      daysEnd: selectedDaysEnd,
      name: selectedMedName,
    })
    calculateMedUse(
      selectedMedName,
      Number(selectedDaysStart),
      Number(selectedDaysEnd)
    )
    calculateMedUseAllMeds(Number(selectedDaysStart), Number(selectedDaysEnd))
    calculateTriptanUse(Number(selectedDaysStart), Number(selectedDaysEnd))
    calculateNsaidUse(Number(selectedDaysStart), Number(selectedDaysEnd))
    constructDateAxis(Number(selectedDaysStart), Number(selectedDaysEnd))
  }

  const toTitleCase = (str) => {
    return str.replace(
      /\w\S*/g,
      (txt) => str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()
    )
  }

  // const monthsGraphData = [
  //   { month: 'May 2020', count: 4 },
  //   { month: 'Jun 2020', count: 7 },
  //   { month: 'Jul 2020', count: 15 },
  //   { month: 'Aug 2020', count: 12 },
  //   { month: 'Sep 2020', count: 8 },
  //   { month: 'Oct 2020', count: 10 },
  //   { month: 'Nov 2020', count: 7 },
  //   { month: 'Dec 2020', count: 8 },
  // ]

  // Nov 2022
  const calculateTriptanUse = (daysStart, daysEnd) => {
    // splicing logbook to only look at the queried days
    let splicedLogs = []
    const queriedDaysStartInMs = Date.now() - 1000 * 60 * 60 * 24 * daysStart
    const queriedDaysEndInMs = Date.now() - 1000 * 60 * 60 * 24 * daysEnd
    const daysBuffer = 10
    const arrayLength =
      daysEnd - daysStart + daysBuffer < logbook.length
        ? daysEnd - daysStart + daysBuffer
        : logbook.length

    if (logbook) {
      for (let i = 0; i < arrayLength; i++) {
        const dateInMs = Date.parse(logbook[i].date)
        if (dateInMs > queriedDaysEndInMs && dateInMs <= queriedDaysStartInMs) {
          splicedLogs.push(logbook[i])
        }
      }
    }

    // if triptan med found in spliced logs, push to new array
    let splicedLogsWithMedUse = []
    let uniqueDates = {}
    // console.log("splicedLogs", splicedLogs)

    for (let log of splicedLogs) {
      for (let med of log.medication) {
        if (
          (med.name === 'suminat' && med.amount) ||
          (med.name === 'zolmitriptan' && med.amount) ||
          (med.name === 'rizatriptan' && med.amount)
        ) {
          splicedLogsWithMedUse.push({
            ...log,
            formattedDate: new Date(log.date).toLocaleString(
              'en-US',
              dateFormat
            ),
          })
        }
      }
    }

    // count how many unique days of queried med use
    for (let log of splicedLogsWithMedUse) {
      if (uniqueDates[log.formattedDate]) {
        uniqueDates[log.formattedDate]++
      } else {
        uniqueDates[log.formattedDate] = 1
      }
    }

    const numberOfMedTriptanUseDays = Object.keys(uniqueDates).length
    setMedTriptanUseDays(numberOfMedTriptanUseDays)
    // setDataFlag(true)
  }

  const calculateNsaidUse = (daysStart, daysEnd) => {
    // splicing logbook to only look at the queried days for NSAID use
    let splicedLogs = []
    const queriedDaysStartInMs = Date.now() - 1000 * 60 * 60 * 24 * daysStart
    const queriedDaysEndInMs = Date.now() - 1000 * 60 * 60 * 24 * daysEnd
    const daysBuffer = 10
    const arrayLength =
      daysEnd - daysStart + daysBuffer < logbook.length
        ? daysEnd - daysStart + daysBuffer
        : logbook.length

    if (logbook) {
      for (let i = 0; i < arrayLength; i++) {
        const dateInMs = Date.parse(logbook[i].date)
        if (dateInMs > queriedDaysEndInMs && dateInMs <= queriedDaysStartInMs) {
          splicedLogs.push(logbook[i])
        }
      }
    }

    // if nsaid med found in spliced logs, push to new array
    let splicedLogsWithMedUse = []
    let uniqueDates = {}

    for (let log of splicedLogs) {
      for (let med of log.medication) {
        if (
          (med.name === 'naproxen' && med.amount) ||
          (med.name === 'toradol' && med.amount) ||
          (med.name === 'diclofenac sodium' && med.amount)
        ) {
          splicedLogsWithMedUse.push({
            ...log,
            formattedDate: new Date(log.date).toLocaleString(
              'en-US',
              dateFormat
            ),
          })
        }
      }
    }

    // count how many unique days of nsaid med use
    for (let log of splicedLogsWithMedUse) {
      if (uniqueDates[log.formattedDate]) {
        uniqueDates[log.formattedDate]++
      } else {
        uniqueDates[log.formattedDate] = 1
      }
    }

    const numberOfMedNsaidUseDays = Object.keys(uniqueDates).length
    setMedNsaidUseDays(numberOfMedNsaidUseDays)
    // setDataFlag(true)
  }

  return (
    <div className="container-lg mt-3 pt-3">
      <div className="col-md-6 container ">
        {loading && (
          <div className="text-center">
            <div
              className="spinner-border text-light"
              style={{ width: '3rem', height: '3rem' }}
              role="status"
            >
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        )}
      </div>
      <div className="col-md-10 container ">
        <div className="container px-4 py-4 mb-3 border rounded">
          <h4>Last Migraine Day: {lastMigraineDate}</h4>
          <h4>Days since last Migraine Day: {daysSinceLastMigraine}</h4>
          <h4>Migraine days in last 30 days: {migraineDaysLast30Days}</h4>
          <h4>Migraine days in last 30 - 60 days: {migraineDaysLast60Days}</h4>
          <h4>Migraine days in last 60 - 90 days: {migraineDaysLast90Days}</h4>
        </div>
        <div className="container px-4 py-4 mb-3 border rounded">
          <h4>
            Any Meds Use Days from {selectedMed.daysStart} to{' '}
            {selectedMed.daysEnd} days ago: {medUseDaysAllMeds}
          </h4>
          <h4>
            Triptan use days from {selectedMed.daysStart} to{' '}
            {selectedMed.daysEnd} days ago: {medTriptanUseDays}
          </h4>
          <h4>
            NSAID use days from {selectedMed.daysStart} to {selectedMed.daysEnd}{' '}
            days ago: {medNsaidUseDays}
          </h4>
        </div>
        <div className="container px-4 py-4 mb-3 border rounded">
          {/* <h4>Migraine Days Per Month</h4> */}
          <VictoryChart
            theme={VictoryTheme.material}
            maxDomain={{ y: 20 }}
            minDomain={{ y: 0 }}
            padding={{ top: 50, bottom: 70, left: 50, right: 20 }}
            labels="title"
            labelComponent={<VictoryLabel angle={-25} textAnchor="start" />}
            style={{
              parent: { fill: '#ffffff' },
            }}
          >
            <VictoryLabel // chart title
              text="Migraine Days per Month"
              x={100}
              y={20}
              // textAnchor="start"
              style={{ fill: '#cccccc' }}
            />
            <VictoryAxis // y-axis
              dependentAxis
              label="Migraine Days"
              style={{
                axisLabel: { padding: 30 },
              }}
              // https://formidable.com/open-source/victory/docs/victory-axis#style
            />
            <VictoryAxis // x-axis
              tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end" />}
            />
            <VictoryLine
              style={{
                data: { stroke: '#c43a31' },
                labels: { fill: '#999999' },
              }}
              size={5}
              data={graphDataMonths}
              labels={({ datum }) => datum.count}
              labelComponent={
                <VictoryLabel renderInPortal dy={-20} active={true} />
              }
              x="month"
              y="count"
              interpolation="monotoneX"
              // animate={{
              //   duration: 2000,
              //   onLoad: { duration: 1000 },
              // }}
            />
          </VictoryChart>
        </div>

        <div className="container px-4 py-4 mb-3 border rounded">
          <h4>
            Chart of any meds use from {selectedMed.daysStart} to{' '}
            {selectedMed.daysEnd} days ago:
          </h4>
          <VictoryChart
            theme={VictoryTheme.material}
            height={(selectedMed.daysEnd - selectedMed.daysStart) * 15}
            // width={200}
            domain={{
              x: [0, selectedMed.daysEnd - selectedMed.daysStart + 1],
              y: [0, 2],
            }}
            padding={{ top: 50, bottom: 20, left: 70, right: 20 }}
          >
            <VictoryAxis
              tickValues={dateAxis}
              tickFormat={dateAxis}
              invertAxis={true}
            />
            <VictoryAxis
              dependentAxis
              orientation="top"
              tickLabelComponent={
                <VictoryLabel angle={-25} textAnchor="start" />
              }
              tickFormat="All meds"
            />
            <VictoryScatter
              horizontal
              style={{ data: { fill: '#c43a31' } }}
              size={5}
              data={graphDataAllMeds}
              x="date"
              y="count"
            />
          </VictoryChart>
        </div>

        <div className="container px-4 py-4 mb-3 border rounded">
          {medsLoading && (
            <div className="text-center">
              <div
                className="spinner-border text-light"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          <div className="form-group mb-3">
            <div className="form-row">
              <div className="col-md-5 mb-2">
                <select className="form-control" id="selectBox">
                  {/* <option>Select...</option> */}
                  {medsList.map((med, index) => {
                    if (med === 'suminat') {
                      return (
                        <option
                          value={med}
                          name="name"
                          defaultValue
                          key={index}
                        >
                          {med}
                        </option>
                      )
                    } else {
                      return (
                        <option value={med} name="name" key={index}>
                          {med}
                        </option>
                      )
                    }
                  })}
                </select>
              </div>
              <div className="col-md-2 mb-2">
                <input
                  className="form-control"
                  value={selectedMed.daysStart}
                  name="name"
                  id="medDaysStart"
                  onChange={handleMedInput}
                />
              </div>
              <div className="col-md-2 mb-2">
                <input
                  className="form-control"
                  value={selectedMed.daysEnd}
                  name="name"
                  id="medDaysEnd"
                  onChange={handleMedInput}
                />
              </div>
              <div className="col-md-2 mb-2">
                <button
                  className="btn btn-success mb-4"
                  onClick={handleMedInput}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
          <h4>
            {toTitleCase(selectedMed.name)} Days from {selectedMed.daysStart} to{' '}
            {selectedMed.daysEnd} days ago: {medUseDays}
          </h4>
        </div>

        <div className="container px-4 py-4 mb-3 border rounded">
          <h4>
            Chart of {toTitleCase(selectedMed.name)} use from{' '}
            {selectedMed.daysStart} to {selectedMed.daysEnd} days ago:
          </h4>
          <VictoryChart
            theme={VictoryTheme.material}
            height={(selectedMed.daysEnd - selectedMed.daysStart) * 15}
            // width={200}
            domain={{
              x: [0, selectedMed.daysEnd - selectedMed.daysStart + 1],
              y: [0, 2],
            }}
            padding={{ top: 50, bottom: 20, left: 70, right: 20 }}
          >
            <VictoryAxis
              tickValues={dateAxis}
              tickFormat={dateAxis}
              invertAxis={true}
            />
            <VictoryAxis
              dependentAxis
              orientation="top"
              tickLabelComponent={
                <VictoryLabel angle={-25} textAnchor="start" />
              }
              tickFormat={[selectedMed.name]}
            />
            <VictoryScatter
              horizontal
              style={{ data: { fill: '#c43a31' } }}
              size={5}
              data={graphData}
              x="date"
              y="count"
            />
          </VictoryChart>
        </div>

        <div className="container px-4 py-4 mb-3 border rounded">
          <h4>Migraine days in:</h4>
          <h4>Jan 2022: {migraineDaysJan2022}</h4>
          <h4>Feb 2022: {migraineDaysFeb2022}</h4>
          <h4>Mar 2022: {migraineDaysMar2022}</h4>
          <h4>Apr 2022: {migraineDaysApr2022}</h4>
          <h4>May 2022: {migraineDaysMay2022}</h4>
          <h4>Jun 2022: {migraineDaysJun2022}</h4>
          <h4>Jul 2022: {migraineDaysJul2022}</h4>
          <h4>Aug 2022: {migraineDaysAug2022}</h4>
          <h4>Sep 2022: {migraineDaysSep2022}</h4>
          <h4>Oct 2022: {migraineDaysOct2022}</h4>
          <h4>Nov 2022: {migraineDaysNov2022}</h4>
          <h4>Dec 2022: {migraineDaysDec2022}</h4>
          <h4>Jan 2023: {migraineDaysJan2023}</h4>
          <h4>Feb 2023: {migraineDaysFeb2023}</h4>
          <h4>Mar 2023: {migraineDaysMar2023}</h4>
          <h4>Apr 2023: {migraineDaysApr2023}</h4>
          <h4>May 2023: {migraineDaysMay2023}</h4>
          <h4>Jun 2023: {migraineDaysJun2023}</h4>
          <h4>Jul 2023: {migraineDaysJul2023}</h4>
          <h4>Aug 2023: {migraineDaysAug2023}</h4>
          <h4>Sep 2023: {migraineDaysSep2023}</h4>
          <h4>Oct 2023: {migraineDaysOct2023}</h4>
          <h4>Nov 2023: {migraineDaysNov2023}</h4>
          <h4>Dec 2023: {migraineDaysDec2023}</h4>
          <h4>Jan 2024: {migraineDaysJan2024}</h4>
          <h4>Feb 2024: {migraineDaysFeb2024}</h4>
          <h4>Mar 2024: {migraineDaysMar2024}</h4>
          <h4>Apr 2024: {migraineDaysApr2024}</h4>
          <h4>May 2024: {migraineDaysMay2024}</h4>
          <h4>Jun 2024: {migraineDaysJun2024}</h4>
          <h4>Jul 2024: {migraineDaysJul2024}</h4>
          <h4>Aug 2024: {migraineDaysAug2024}</h4>
          <h4>Sep 2024: {migraineDaysSep2024}</h4>
        </div>

        {/* <div className="container px-4 py-4 mb-3 border rounded">
          <h4>
            Chart of {toTitleCase(selectedMed.name)} use over the past months:
          </h4>
          <VictoryChart
            theme={VictoryTheme.material}
            // height={(selectedMed.daysEnd - selectedMed.daysStart) * 15}
            // width={200}
            // domain={{ x: [0, (selectedMed.daysEnd - selectedMed.daysStart + 1)], y: [0, 2] }}
            padding={{ top: 50, bottom: 60, left: 40, right: 20 }}
          >
            <VictoryAxis dependentAxis />
            <VictoryAxis
              tickLabelComponent={<VictoryLabel angle={-45} textAnchor="end" />}
            />
            <VictoryBar
              style={{ data: { fill: '#c43a31' }, labels: { fill: 'white' } }}
              data={monthsGraphData}
              x="month"
              y="count"
              labels={({ datum }) => datum.y}
              labelComponent={<VictoryLabel dy={30} />}
            />
          </VictoryChart>
        </div> */}
      </div>
    </div>
  )
}

export default Dashboard
