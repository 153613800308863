import React, { useState, useEffect, useRef } from 'react'
import LogbookService from '../services/logbook.service'
import MedsListService from '../services/medslist.service'
import DatePicker from 'react-datepicker'
import { Link } from 'react-router-dom'

import 'react-datepicker/dist/react-datepicker.css'

const AddLog = () => {
  const initialLogState = {
    id: null,
    title: '',
    date: '',
    migraineDay: false,
    medication: [
      {
        name: '',
        amount: '',
        units: '',
      },
    ],
    entry: '',
  }
  const defaultMed = {
    name: '',
    amount: '',
    units: '',
  }

  const [medsList, setMedsList] = useState([defaultMed])
  const [medsListFlag, setMedsListFlag] = useState(false)
  const [medsLoading, setMedsLoading] = useState(false)
  const [log, setLog] = useState(initialLogState)
  const [submitted, setSubmitted] = useState(false)
  const [logDate, setLogDate] = useState(new Date())
  const [medFlag, setMedFlag] = useState(false)
  const [med, setMed] = useState(defaultMed)
  const [logTitle, setLogTitle] = useState(null)
  const [logTitleFlag, setLogTitleFlag] = useState(false)
  const isFirstRender = useRef(true)

  // retrieving MedsList from api
  const getMedsList = () => {
    MedsListService.getAll()
      .then((res) => {
        const data = res.data
        let medNamesAndUnits = []
        data.map((med) => {
          return medNamesAndUnits.push({
            name: med.name,
            amount: '',
            units: med.units,
          })
        })
        setMedsList(medNamesAndUnits)
        setMedsListFlag(true)
        setMedsLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setMedsLoading(false)
      })
  }

  // retrieving LogBook from api
  const retrieveLogbook = () => {
    LogbookService.getAll()
      .then((res) => {
        let arr = res.data
        arr.sort((a, b) => new Date(b.date) - new Date(a.date))
        setLogTitle('Log ' + (Number(arr[0].title.split(' ')[1]) + 1))
        setLogTitleFlag(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  // On first render, call MedsList and LogBook for title
  useEffect(() => {
    getMedsList()
    retrieveLogbook()
    setMedsLoading(true)
    isFirstRender.current = false
  }, [])

  // setting received MedsList to Log object
  useEffect(() => {
    if (medsListFlag) {
      setLog({
        ...log,
        medication: medsList,
      })
      setMedsListFlag(false)
    }
  }, [medsListFlag, log, logTitle, medsList])

  // setting constructed title to Log object
  useEffect(() => {
    if (logTitleFlag) {
      setLog({
        ...log,
        title: logTitle,
      })
      setLogTitleFlag(false)
    }
  }, [logTitleFlag, log, logTitle])

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setLog({ ...log, [name]: value })
  }

  const handleMigraineDayChange = (toggle) => {
    const value = toggle === 'yes' ? true : false
    setLog({ ...log, migraineDay: value })
  }

  const handleMedInput = (event) => {
    const { name, value } = event.target
    setMed({ ...med, [name]: value })
    setMedFlag(true)
  }

  useEffect(() => {
    if (medFlag) {
      for (const item of medsList) {
        if (item.name === med.name) {
          item.amount = med.amount
          med.amount = ''
        }
      }
      setLog({
        ...log,
        medication: [...medsList],
      })
      setMedFlag(false)
    }
  }, [med, log, medFlag, medsList])

  const saveLog = () => {
    let data = {
      title: log.title,
      date: logDate,
      migraineDay: log.migraineDay,
      entry: log.entry,
      medication: [...log.medication],
    }

    LogbookService.create(data)
      .then((res) => {
        setLog({
          id: res.data.id,
          title: res.data.title,
          entry: res.data.entry,
          migraineDay: res.data.migraineDay,
          date: res.data.date,
          medication: res.data.medication,
        })
        setSubmitted(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const newLog = () => {
    getMedsList()
    retrieveLogbook()
    setMedsLoading(true)
    setLog(initialLogState)
    setLogDate(new Date())
    setSubmitted(false)
  }

  return (
    <div className="container-lg mt-3 pt-3">
      {submitted ? (
        <div className=" ">
          <h4 className="py-4 d-flex justify-content-around">
            The log was submitted successfully.
          </h4>
          <div className="d-flex justify-content-around">
            <button className="btn btn-success" onClick={newLog}>
              Add another log
            </button>
            <Link className="btn btn-info" to={'/dashboard'}>
              Dashboard
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <h4 className="py-1">Add new log</h4>
          <div>
            <div className="form-group">
              <label htmlFor="title">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                required
                value={log.title}
                onChange={handleInputChange}
                name="title"
              />
            </div>
            <div className="form-group">
              <label htmlFor="date">Date</label>
              <DatePicker
                selected={logDate}
                className="form-control"
                wrapperClassName="form-control"
                width="100%"
                onChange={(date) => setLogDate(date)}
                name="date"
                value={logDate}
                showTimeSelect
                timeFormat="HH:mm"
                timeIntervals={15}
                timeCaption="time"
                dateFormat="MMMM d, yyyy h:mm aa"
              />
            </div>
            <div className="container border rounded py-1 mb-3 d-flex">
              {/* <div className="row"> */}
              <div className="col-md-6" htmlFor="migraineDay">
                Migraine Day?
              </div>
              <div className="col-md-6">
                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="migraineDay"
                    id="toggleYes"
                    value={log.migraineDay}
                    onChange={() => handleMigraineDayChange('yes')}
                    required
                  />
                  <span className="" htmlFor="toggleYes">
                    Yes
                  </span>
                </div>
                <div className="form-check-inline">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="migraineDay"
                    id="toggleNo"
                    value={log.migraineDay}
                    onChange={() => handleMigraineDayChange('no')}
                    required
                  />
                  <span className="" htmlFor="toggleYes">
                    No
                  </span>
                </div>
              </div>
              {/* </div> */}
            </div>
            <div className="form-group mb-3 ">
              <label htmlFor="date">Medication</label>
              {medsLoading && (
                <div className="text-center">
                  <div
                    className="spinner-border text-light"
                    style={{ width: '3rem', height: '3rem' }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              )}
              {log &&
                log.medication.map((med, index) => {
                  return (
                    <div className="form-row">
                      <div className="col-md-6 mb-2">
                        <input
                          className="form-control"
                          value={med.name}
                          name="name"
                          onClick={handleMedInput}
                          readOnly={true}
                        />
                      </div>
                      <div className="col-md-6 mb-2">
                        <input
                          className="form-control"
                          type="text"
                          value={med.amount}
                          index={med.name}
                          onChange={handleMedInput}
                          name="amount"
                          placeholder="amount"
                        />
                      </div>
                    </div>
                  )
                })}
            </div>
            <div className="form-group">
              <label htmlFor="entry">Entry</label>
              <textarea
                type="text"
                rows="10"
                className="form-control"
                id="entry"
                required
                value={log.entry}
                onChange={handleInputChange}
                name="entry"
                placeholder="Entry"
              />
            </div>
            <button onClick={saveLog} className="btn btn-success mb-4">
              Submit
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default AddLog
