import React, { useState, useEffect } from 'react'
import MedsListService from '../services/medslist.service'

const MedsList = (props) => {
  const defaultMed = {
    name: '',
    units: '',
  }
  const [medsList, setMedsList] = useState([defaultMed])
  const [currentMed, setCurrentMed] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [message, setMessage] = useState('')
  const [inputMed, setInputMed] = useState(false)
  const [newMed, setNewMed] = useState(defaultMed)
  const [loading, setLoading] = useState(false)

  const getMedsList = () => {
    MedsListService.getAll()
      .then((res) => {
        setMedsList(res.data)
        setLoading(false)
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    getMedsList()
    setLoading(true)
  }, [])

  const refreshList = () => {
    getMedsList()
    setCurrentMed(null)
    setCurrentIndex(-1)
    setLoading(true)
  }

  const setActiveMed = (med, index) => {
    setCurrentMed(med)
    setCurrentIndex(index)
    setMessage('')
  }

  const handleInputChange = (event) => {
    const { name, value } = event.target
    setCurrentMed({ ...currentMed, [name]: value })
  }

  const handleNewMedInputChange = (event) => {
    const { name, value } = event.target
    setNewMed({ ...newMed, [name]: value })
  }

  const submitMed = () => {
    const data = {
      name: newMed.name,
      units: newMed.units,
    }

    MedsListService.create(data)
      .then((res) => {
        setMessage('The med was submitted successfully.')
        setInputMed(false)
        refreshList()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const updateMed = () => {
    MedsListService.update(currentMed.id, currentMed)
      .then((res) => {
        setMessage(res.data.message)
        refreshList()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const deleteMed = () => {
    MedsListService.remove(currentMed.id)
      .then((res) => {
        setMessage(res.data.message)
        refreshList()
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <div className="list row py-4">
      <div className="col-md-6 container">
        <h4 className="py-2">Medication List</h4>
        <ul className="list-group">
          {loading && (
            <div className="text-center">
              <div
                className="spinner-border text-light"
                style={{ width: '3rem', height: '3rem' }}
                role="status"
              >
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          )}
          {medsList &&
            medsList.map((med, index) => {
              return (
                <li
                  className={
                    'list-group-item d-flex justify-content-between ' +
                    (index === currentIndex
                      ? 'dark-log-active'
                      : 'dark-log-item')
                  }
                  onClick={() => setActiveMed(med, index)}
                  key={index}
                >
                  <div>{med.name}</div>
                  <div>{med.units}</div>
                </li>
              )
            })}
        </ul>
        <div className="my-4">
          <button
            type="submit"
            className="btn btn-light"
            onClick={() => {
              setCurrentMed(null)
              setCurrentIndex(-1)
              return setInputMed(true)
            }}
          >
            Add new med
          </button>
        </div>
        <div className="my-4">
          <p>{message}</p>
        </div>
      </div>
      <div className="col-md-6 container">
        {currentMed ? (
          <div className="container px-4 py-4 my-4 border rounded">
            <h5>Selected Med</h5>
            <hr />
            <div className="form-group">
              <label>
                <strong>Name:</strong>
              </label>{' '}
              <input
                type="text"
                className="form-control my-2"
                value={currentMed.name}
                onChange={handleInputChange}
                name="name"
                placeholder="Medication name"
              />
            </div>
            <div>
              <label>
                <strong>Units:</strong>
              </label>{' '}
              <input
                type="text"
                className="form-control my-2"
                value={currentMed.units}
                onChange={handleInputChange}
                name="units"
                placeholder="Medication units"
              />
            </div>
            <div className="my-4">
              <button
                type="submit"
                className="btn btn-success"
                onClick={updateMed}
              >
                Update
              </button>
              <button className="btn btn-warning mx-4" onClick={deleteMed}>
                Delete
              </button>
            </div>
          </div>
        ) : inputMed ? (
          <div className="container px-4 py-4 my-4 border rounded">
            <h5>Input New Med</h5>
            <hr />
            <div className="form-group">
              <label>
                <strong>Name:</strong>
              </label>{' '}
              <input
                type="text"
                className="form-control my-2"
                value={newMed.name}
                onChange={handleNewMedInputChange}
                name="name"
                placeholder="Medication name"
              />
            </div>
            <div>
              <label>
                <strong>Units:</strong>
              </label>{' '}
              <input
                type="text"
                className="form-control my-2"
                value={newMed.units}
                onChange={handleNewMedInputChange}
                name="units"
                placeholder="Medication units"
              />
            </div>
            <div className="my-4">
              <button
                type="submit"
                className="btn btn-success"
                onClick={submitMed}
              >
                Submit
              </button>
              <button
                className="btn btn-warning mx-4"
                onClick={() => setCurrentMed(null)}
              >
                Clear
              </button>
            </div>
          </div>
        ) : (
          <div>
            <br />
            <p></p>
          </div>
        )}
      </div>
    </div>
  )
}

export default MedsList
