import React from 'react'
import { Redirect } from 'react-router-dom'
import jwt_decode from 'jwt-decode'

const ProtectedRoute = (props) => {
  const Component = props.component

  const token = JSON.parse(localStorage.getItem('user'))['accessToken']
  const currentTime = new Date().getTime() / 1000
  const isExpired = jwt_decode(token).exp < currentTime

  const isAuthenticated = token && !isExpired

  if (!isAuthenticated) {
    localStorage.clear()
    window.location.replace('/login')
  }

  return (
    <>
      {isAuthenticated ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: '/login' }} />
      )}
    </>
  )
}

export default ProtectedRoute
